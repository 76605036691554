<template>
    <modal ref="modalCrearAdministrador" :titulo="`${editar ? 'Editar' : 'Crear'} permiso`" tamano="modal-lg" no-aceptar :adicional="editar ? 'Editar' : 'Crear'" @adicional="crearEditar">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 justify-center">
                <div class="col-5">
                    <ValidationProvider v-slot="{errors}" vid="nombre" rules="required|max:30" name="nombre">
                        <p class="text-muted2 pl-2 f-14">
                            Nombre del permiso
                        </p>
                        <el-input v-model="model.nombre" maxlength="30" placeholder="Nombre" class="w-100">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-5">
                    <div class="row mx-0 h-100">
                        <div class="col pt-3 d-middle">
                            <div class="px-2 py-2 cr-pointer mt-1" :class="model.tipo == 2 ? 'button-cursor border br-8' : 'show-button'" @click="cambiarTipo(1)">
                                <i class="icon-eye f-18" />
                            </div>
                            <span class="mx-1">
                                Acceso
                            </span>
                        </div>
                        <div class="col pt-3 d-middle">
                            <div class="px-2 py-2 cr-pointer mt-1" :class="model.tipo == 1 ? 'button-cursor border br-8' : 'show-button'" @click="cambiarTipo(2)">
                                <i class="icon-cursor-default-outline f-18" />
                            </div>
                            <span class="mx-1">
                                Acción
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mx-0 my-4 justify-center">
                <div class="col-5">
                    <p class="text-muted2 pl-2 f-14">
                        Módulo
                    </p>
                    <el-select v-model="model.id_modulo" :disabled="editar" placeholder="Seleccionar módulo" class="w-100">
                        <el-option
                        v-for="item in modulos"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
                <div class="col-5">
                    <ValidationProvider v-slot="{errors}" vid="guardian" rules="required|max:50" name="guardian">
                        <p class="text-muted2 pl-2 f-14">
                            Guardián
                        </p>
                        <el-input v-model="model.guardian" maxlength="50" placeholder="Guardían" class="w-100">
                            <i v-if="errors.length" slot="suffix" class="icon-attention-alt text-danger el-input__icon f-17" />
                        </el-input>
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
            <div class="row mx-0 justify-center">
                <div class="col-10">
                    <ValidationProvider v-slot="{errors}" vid="descripcion" rules="required|max:300" name="descripción">
                        <p class="text-muted2 pl-2 f-14">
                            Descripción
                        </p>
                        <el-input v-model="model.descripcion" maxlength="300" placeholder="Descripción" class="w-100" type="textarea" :rows="2" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Permisos from "~/services/permisos"
export default {
    data(){
        return {
            modulos: [],
            model: {
                tipo: 1,
                nombre: null,
                guardian: null,
                id_modulo: null,
                descripcion: null,
            },
            editar: false
        }
    },
    methods: {
        toggle(data){
            this.listarModulos()
            if (data){
                this.model = _.cloneDeep(data)
                this.editar = true
            }else{
                this.editar = false
            }
            this.$refs.modalCrearAdministrador.toggle();
        },
        async listarModulos(){
            try {
                
                const { data } = await Permisos.listarModulos()
                this.modulos = data
                
            } catch (e){
                this.error_catch(e)
            }
        },
        crearEditar(){
            if (this.editar){
                this.editarPermiso()
            } else {
                this.crearPermiso()
            }
        },
        async crearPermiso(){
            try {
                let valid = await this.$refs.validacion.validate()

                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return
                }
                const { data } = await Permisos.crearPermiso(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.limpiar()
                this.$emit('crear', data.model)

                this.$refs.modalCrearAdministrador.toggle();
            } catch (e){
                this.error_catch(e, 'validacion')
            }
        },
        async editarPermiso(){
            try {

                let valid = await this.$refs.validacion.validate()

                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return
                }

                const { data } = await Permisos.editarPermiso(this.model)
                this.notificacion('Mensaje', data.mensaje, 'success')
                this.limpiar()
                this.$emit('editar', data.model)
                this.$refs.modalCrearAdministrador.toggle();
                
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model = {
                tipo: 1,
                nombre: null,
                guardian: null,
                id_modulo: null,
                descripcion: null,
            }
            this.$refs.validacion.reset();
        },
        cambiarTipo(val){
            this.model.tipo = val
            this.$refs.validacion.reset();
        },
    }
}
</script>

<style lang="scss" scoped>
section{
    border-radius: 12px 12px 0px 0px;
}
.br-12{
    border-radius: 12px !important;
}
.show-button{
    background-color: #FF5E2B;
    color: #ffffff;
    border-radius: 8px;
}
.button-cursor{
    opacity: 0.5;
}
.text-black{
    color: #000000;
}
.bg-light-f5{
    background-color: #F5F5F5;
}
.button-accept{
    background-color: #F5F5F5;
    color: #707070;
    &:hover{
        color: #ffffff !important;
        background-color: #707070 !important;
    }
}
.hover-icons{
    color: #707070;
    &:hover{
        color: #000000;
    }
}
.h{
    background-color: #F5F5F5;
    border: #DBDBDB;
    color: #000000;
    border-radius: 12px;    
}
.cr-bloqueo{
    cursor: not-allowed;
}
</style>